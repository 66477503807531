import React, { FunctionComponent } from 'react';
import { FiBookmark, FiMoon, FiUsers, FiCheckSquare, FiCalendar, FiClock } from 'react-icons/fi';

const ServiceList = [
  {
    icon: <FiBookmark />,
    title: 'Eventos Empresiales',
    description:
      'Previene y cuida la salud de tus asistentes en encuentros deportivos, inauguraciones y demás eventos con aglomeración de personas.',
  },
  {
    icon: <FiMoon />,
    title: 'Turno Hospitalario 24 horas',
    description:
      'Acompañamiento hospitalario por personal de enfermería totalmente calificado para brindar cuidado y apoyo, siendo un aliado en los momentos en que tú y la familia lo requieran.',
  },
  {
    icon: <FiCalendar />,
    title: 'Turno Hospitalario 12 horas',
    description:
      'Acompañamiento hospitalario por personal de enfermería totalmente calificado para brindar cuidado y apoyo, siendo un aliado en los momentos en que tú y la familia lo requieran.',
  },
  {
    icon: <FiClock />,
    title: 'Turno Domiciliario 12 horas',
    description:
      'Acompañamiento en domicilio por personal totalmente calificado y capacitado, brindando atención y servicio en pro de la recuperación y estabilidad de un paciente.',
  },
  {
    icon: <FiUsers />,
    title: 'Trámite de medicamentos',
    description:
      'Apoyo en trámites y reclamación de medicamentos, en momentos que las obligaciones y agenda te lo impiden,  adicional brindamos el servicio de gestión de citas médicas ante tu EPS.',
  },
  {
    icon: <FiCheckSquare />,
    title: 'Acompañamiento a citas médicas',
    description:
      'Servicio de acompañamiento a citas médicas por personal de enfermería, dando soporte y atención requerida en la consulta.',
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Services: FunctionComponent<{ column: string; item: any }> = ({ column, item }) => {
  const ServiceContent = ServiceList.slice(0, item);

  return (
    <React.Fragment>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div className={`${column}`} key={i}>
            <div className="service service__style--2">
              <div className="icon">
                {val.icon}
                <h3 className="title">{val.title}</h3>
              </div>
              <div className="content">
                <p>{val.description}</p>
              </div>
            </div>
          </div>
        ))}
        <div className="btn">
          <a className="rn-button-style--2 btn-solid" href="/">
            Agenda ya!
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Services;
