import React, { FunctionComponent, useEffect } from 'react';

const Privacy: FunctionComponent = () => {
  useEffect(() => {}, []);

  return (
    <div style={{ backgroundColor: 'white', padding: '80px' }}>
      <h1>Privacy Policy</h1>
      <p>
        We, Health Services, recognize and respect your privacy. This Privacy Policy explains how we
        collect, use, and protect your personal information when you use our website and services,
        including when you sign in using Facebook Login. By using our website and services, you
        agree to the terms and conditions of this Privacy Policy.
      </p>
      <p>
        When you sign in using Facebook Login, we collect certain information from your Facebook
        profile, such as your name, email address, profile picture, and other publicly available
        information. We use this information to create your account and to personalize your
        experience on our website and services.
      </p>
      <p>
        In addition to the information collected from Facebook, we may also collect other personal
        information from you, such as your contact information, payment information, and other
        information you choose to provide us.
      </p>
      <p>
        We use the information we collect to provide you with our website and services, to
        communicate with you, to personalize your experience, and to improve our website and
        services. We may also use your information for marketing purposes, but we will always give
        you the option to opt out of receiving marketing communications from us.
      </p>
      <p>
        We may disclose your personal information to third parties who provide services on our
        behalf, such as payment processors and email service providers. We may also disclose your
        information if we believe it is necessary to comply with a legal obligation or to protect
        our rights, property, or safety.
      </p>
      <p>
        We take reasonable measures to protect your personal information from unauthorized access,
        disclosure, and misuse. However, no security measures are foolproof, and we cannot guarantee
        the security of your information.
      </p>
      <p>
        We may update this Privacy Policy from time to time. If we make material changes to this
        policy, we will notify you by email or by posting a notice on our website.
      </p>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at
        healthservice25@gmail.com.
      </p>
    </div>
  );
};

export default Privacy;
