import React, { Fragment } from 'react';
import { Parallax } from 'react-parallax';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';

import Header from '../../components/header';
import Footer from '../../components/footer';
import Counter from '../../components/counter';
import Services from '../../components/services';
import Helmet from '../../components/common/Helmet';
import Slide from '../../assets/images/slide.webp';
import ServicesImage from '../../assets/images/services.webp';
import CounterImage from '../../assets/images/counter.webp';

const SlideList = [
  {
    textPosition: 'text-center',
    category: '',
    title: 'Health Services',
    description: 'Bienvenido a la plataforma de enfermería preferida de los Colombianos',
  },
];

const Home = () => (
  <Fragment>
    <Helmet pageTitle="Home" />

    <Header
      headertransparent="header--transparent header--fixed"
      colorblack="color--black"
      logoname="logo.webp"
    />

    {/* Start Slider Area   */}
    <div className="slider-wrapper">
      {/* Start Single Slide */}
      <Parallax bgImage={Slide} strength={700} bgClassName="parallax">
        {SlideList.map((value, index) => (
          <div
            className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
            key={index}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ''}
                    {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                    {value.description ? <p className="description">{value.description}</p> : ''}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Parallax>
    </div>
    {/* End Slider Area   */}

    {/* Start Service Area  */}
    <div id="services">
      <Parallax
        id="services"
        className="rn-paralax-service"
        bgImage={ServicesImage}
        strength={1000}
      >
        <div className="service-area ptb--120">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                  <h2>Servicios</h2>
                  <p>Diferentes servicios para ofrecer la mejor experiencia.</p>
                </div>
              </div>
            </div>
            <Services item="6" column="col-lg-4 col-md-6 col-sm-6 col-12" />
          </div>
        </div>
      </Parallax>
    </div>
    {/* End Service Area  */}

    {/* Start CounterUp Area */}
    <div id="about">
      <Parallax
        className="rn-counterup-area rn-paralax-counterup"
        bgImage={CounterImage}
        strength={1000}
      >
        <div className="counterup-area ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                  <h3>Nuestros datos curiosos</h3>
                </div>
              </div>
            </div>
            <Counter />
          </div>
        </div>
      </Parallax>
    </div>

    {/* End CounterUp Area */}

    {/* Start Back To Top */}
    <div className="backto-top">
      <ScrollToTop showUnder={160}>
        <FiChevronUp />
      </ScrollToTop>
    </div>
    {/* End Back To Top */}

    <Footer />
  </Fragment>
);

export default Home;
