import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';

export const Api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://us-central1-health-5b1b3.cloudfunctions.net/apps/api/v1',
  }),
  endpoints: (builder) => ({
    getService: builder.query({
      query: (id: string) => `nurseAvailability/${id}`,
    }),
    confirmService: builder.mutation({
      query: (data) => ({
        url: 'nurseAvailability/confirm',
        method: 'POST',
        body: data,
      }),
    }),
    rejectService: builder.mutation({
      query: (data) => ({
        url: 'nurseAvailability/reject',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetServiceQuery,
  useConfirmServiceMutation,
  useRejectServiceMutation,
  endpoints,
} = Api;
