import React, { FunctionComponent, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { FiX, FiMenu } from 'react-icons/fi';

import logo from '../../assets/images/logo/logo.webp';

const Header: FunctionComponent<{ color?: string }> = ({ color = 'default-color' }) => {
  const menuTrigger = () => {
    document.querySelector('.header-wrapper')?.classList.toggle('menu-open');
  };

  const closeMenuTrigger = () => {
    document.querySelector('.header-wrapper')?.classList.remove('menu-open');
  };

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));

      if (element) {
        setTimeout(() => element.scrollIntoView(), 0);
      }
    }
  }, [hash]);

  const elements = document.querySelectorAll('.has-droupdown > a');
  for (const i in elements) {
    // eslint-disable-next-line no-prototype-builtins
    if (elements.hasOwnProperty(i)) {
      elements[i].onClick = function () {
        document.querySelector('.submenu')?.classList.toggle('active');
        document.querySelector('.submenu')?.classList.toggle('open');
      };
    }
  }
  return (
    <header className={`header-area formobile-menu header--transparent ${color}`}>
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Health Services" />
            </a>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li>
                <Link to="#">Home</Link>
              </li>
              <li>
                <Link to="#services">Servicios</Link>
              </li>
              <li>
                <Link to="#about">About</Link>
              </li>
            </ul>
          </nav>
          <div className="header-btn">
            <a className="rn-btn" href="https://ideascol.typeform.com/nurse-register">
              <span>Trabaja con nosotros</span>
            </a>
          </div>
          {/* Start Humberger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20">
            <span onClick={menuTrigger} className="menutrigger text-white">
              <FiMenu />
            </span>
          </div>
          {/* End Humberger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={closeMenuTrigger} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
