import React, { FunctionComponent, useEffect } from 'react';

export interface IPageScrollTopProps {
  children: React.ReactElement;
}

const PageScrollTop: FunctionComponent<IPageScrollTopProps> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return children;
};

export default PageScrollTop;
