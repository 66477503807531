import React, { FunctionComponent } from 'react';
import { Routes, Route } from 'react-router-dom';

import PageScrollTop from './components/PageScrollTop';
import Error404 from './components/common/error404';
import Home from './containers/home';
import Confirm from './containers/confirm';
import Privacy from './containers/privacy';

const App: FunctionComponent = () => {
  return (
    <PageScrollTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/confirm/:service" element={<Confirm />} />
        <Route path="/404" element={<Error404 />} />
        <Route element={<Error404 />} />
      </Routes>
    </PageScrollTop>
  );
};

export default App;
