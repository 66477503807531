import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';

const PageHelmet: FunctionComponent<{ pageTitle: string }> = ({ pageTitle }) => (
  <React.Fragment>
    <Helmet>
      <title>{pageTitle} || HS </title>
      <meta
        name="description"
        content="Aplicación de Enfermería, Trabajo para enfermeras, Contrata enfermera, Acompañamiento"
      />
    </Helmet>
  </React.Fragment>
);

export default PageHelmet;
