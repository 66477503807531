import styled from 'styled-components';

import { media } from '../../theme/media';

export const Container = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  justify-content: center;
  gap: 40px;
  height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : 'calc(100vh - 370px)')};
  margin-bottom: 80px;

  ${media.lessThan<{ isFullHeight?: boolean }>('md')`
    align-items: center;
    flex-direction: column;
    height: ${({ isFullHeight }) => (isFullHeight ? '100vh' : 'calc(100vh - 150px)')};
  `}
`;

export const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 1px 1px 1px 1px lightgray;
  height: 400px;
  width: 400px;

  .col-lg-12 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .label {
    font-weight: 600;
    margin-right: 8px;
  }

  h4 {
    margin: 30px 0;
  }

  ${media.lessThan('md')`
    .col-lg-12 {
      padding: 10px;
    }

    width: 80%;
  `}
`;
