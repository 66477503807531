import React, { FunctionComponent } from 'react';

const Loader: FunctionComponent = () => (
  <div className="loader">
    <div className="lds-heart">
      <div></div>
    </div>
  </div>
);

export default Loader;
