import React, { FunctionComponent, useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { ImWhatsapp } from 'react-icons/im';

import { useGetServiceQuery, useConfirmServiceMutation, useRejectServiceMutation } from '../../api';
import ConfirmBackground from '../../assets/images/confirm2.jpg';
import Loader from '../../components/loader';

import { Card, Container } from './confirm.styled';

const GOOGLE_MAP_API_URL = 'https://www.google.com/maps/embed/v1/directions';
const GOOGLE_MAP_API_KEY = 'AIzaSyC78R6xoGKm9qxYwPDpxO2HTq36tks29FM';

enum Statuses {
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  WAITING = 'WAITING',
}

const Confirm: FunctionComponent = () => {
  const { service } = useParams();

  moment.locale('es'); // es
  const { isLoading, data, refetch } = useGetServiceQuery(service || '');
  const [confirmService, { isLoading: isConfirmLoading }] = useConfirmServiceMutation();
  const [rejectService, { isLoading: isRejectLoading }] = useRejectServiceMutation();

  const onConfirm = async () => {
    await confirmService(data);
    await refetch();
  };

  const onReject = async () => {
    await rejectService(data);
    await refetch();
  };

  if (isLoading) {
    return <Loader />;
  }

  const {
    address_appointment: addressAppointment,
    appointment_date: { seconds: appointmentDate = 1 } = {},
    name_patient: patient,
    number_contact: numberContact,
    sourceLocation,
    nurseName,
  } = data;

  const RejectedState = (
    <Parallax className="rn-paralax-service" bgImage={ConfirmBackground} strength={1000}>
      <Container
        isFullHeight
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Card style={{ padding: 20 }}>
          <div className="col-lg-12">
            <div className="row">
              <h4 style={{ textAlign: 'center' }}>
                Hola. <br /> Gracias por regresar
              </h4>
            </div>
            <div className="row">
              <span className="label" style={{ textAlign: 'center' }}>
                Este servicio ha sido rechazado y no se encuentra disponible.
              </span>
            </div>
            <div className="row">
              <span className="label" style={{ textAlign: 'center', marginTop: 40 }}>
                Si tienes alguna duda, escríbenos
                <br />
                <a
                  rel="noreferrer"
                  href="https://api.whatsapp.com/send?phone=573003702616&text=Hola%2C%20necesito%20ayuda"
                  target="_blank"
                >
                  <ImWhatsapp
                    fontSize={35}
                    style={{ cursor: 'pointer', marginTop: 16 }}
                    color="#44C152"
                  />
                </a>
              </span>
            </div>
          </div>
        </Card>
      </Container>
    </Parallax>
  );

  const date = new Date(appointmentDate * 1000);

  const getMapUrl = () => {
    const { latitude, longitude } = sourceLocation || {};
    return `${GOOGLE_MAP_API_URL}?origin=${latitude},${longitude}&destination=${addressAppointment}&key=${GOOGLE_MAP_API_KEY}`;
  };

  return data?.status === Statuses.REJECTED ? (
    RejectedState
  ) : (
    <Parallax className="rn-paralax-service" bgImage={ConfirmBackground} strength={1000}>
      <div className="service-area ptb--120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                <h2>
                  Hola, {nurseName} <br /> Nuevo servicio disponible
                </h2>
                <p>Contamos contigo para ofrecer la mejor experiencia.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Card>
          <div className="col-lg-12">
            <div className="row">
              {data?.status === Statuses.CONFIRMED ? (
                <h4>Servicio confirmado</h4>
              ) : (
                <h4>Datos del servicio</h4>
              )}
            </div>
            <div className="row">
              <span className="label">Nombre:</span>
              <span>{patient}</span>
            </div>
            <div className="row">
              <span className="label">Dirección:</span>
              <span>{addressAppointment}</span>
            </div>
            <div className="row">
              <span className="label">Telefono:</span>
              <span>{numberContact}</span>
            </div>
            <div className="row">
              <span className="label">Fecha:</span>
              <span>{moment(date).locale('es').format('MMMM Do YYYY, h:mm:ss a')}</span>
            </div>
            {data?.status === Statuses.WAITING && (
              <div className="row">
                <button className="btn btn-confirm" onClick={onConfirm} disabled={isConfirmLoading}>
                  <span>Aceptar</span>
                </button>
                <button className="btn btn-reject" onClick={onReject} disabled={isRejectLoading}>
                  <span>Rechazar</span>
                </button>
              </div>
            )}
          </div>
        </Card>
        <Card>
          <iframe
            width="100%"
            height="400"
            frameBorder="0"
            src={getMapUrl()}
            style={{ borderRadius: 8 }}
            allowFullScreen
          ></iframe>
        </Card>
      </Container>
    </Parallax>
  );
};

export default Confirm;
